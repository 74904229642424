.navContainer {
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding: 1rem;
  z-index: 9999;
  grid-area: navbar;
}

.logo {
  font-size: 1.5rem;
}

.socialLinks {
  text-decoration: none;
  color: white;
}

.icons {
  color: white;
  font-size: 1.5rem;
  margin-left: auto;
}

@media (min-width: 1024px) {
  .navContainer {
    align-items: flex-start;
    padding-left: 6%;
    padding-right: 6%;
    align-items: flex-end;
  }

  .logo {
    color: #5658dd;
    font-size: 2.5rem;
  }

  .icons {
    font-size: 2rem;
  }
}
