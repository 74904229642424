.video {
  position: absolute;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  z-index: 2;
}

.HomeNavContainer {
  display: none;
}

.homeContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 60vh 40vh;
  grid-template-areas:
    "textContent"
    "navbar";
}

.textContent {
  display: flex;
  margin-top: 3rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  z-index: 34;
  font-family: "Circular Std Book", sans-serif;
  justify-content: space-around;
  grid-area: textContent;
}

.newsletter {
  background: #5658dd;
  border-radius: 12px;
  padding: 0.7rem;
  display: flex;
  align-items: center;
}

.letterIcon {
  width: 2rem;
  margin-right: 0.3rem;
}

.homeInput {
  border: none;
  background: none;
  color: white;
  font-weight: 500;
  font-size: 0.9rem;
}

.homeInput:focus {
  outline: none;
}

.heading {
  font-weight: bold;
  font-size: 2rem;
}

.homePara {
  font-size: 1.3rem;
}

@media (min-width: 1024px) {
  .homeContainer {
    grid-template-columns: 43vw 57vw;
    grid-template-rows: 13vh 60vh;
    grid-template-areas:
      "navbar navbar"
      "textContent video";
  }

  .textContent {
    align-items: flex-start;
    padding-left: 12%;
    color: black;
  }

  .video {
    width: 50vw;
    right: 0;
  }
}
